<template>
  <div>
    <CardWithFooter
      class="my-4"
      :level="EQuestionnaireLevel.Question"
      :title="defaultingLangEntry.title"
    >
      <template #content>
        <div class="flex flex-row text-darkGray my-4">
          <div
            class="bg-primary h-2 w-2 self-center mr-3 whitespace-pre-wrap"
          />
          {{ defaultingLangEntry.description }}
        </div>
        <InfoText v-if="hasClarification" class="mt-8 mb-3 whitespace-pre-wrap">
          <p>{{ defaultingLangEntry.clarification }}</p>
        </InfoText>
        <div
          v-if="currentQuestion.type === EQuestionType.SingleChoice"
          class="pt-1"
        >
          <BorderedRadio
            v-for="(option, idx) in currentQuestion.options"
            :key="getTranslatedOptions(option.translations)"
            :value="idx"
            :modelValue="currentAnswer.multipleChoiceOption[0]"
            class="min-w-[320px] mr-6 my-6"
            :label="getTranslatedOptions(option.translations)"
            :disabled="true"
          />
        </div>
        <div
          v-else-if="currentQuestion.type === EQuestionType.MultipleChoice"
          class="pt-1"
        >
          <BorderedCheckbox
            v-for="(option, idx) in currentQuestion.options"
            :key="getTranslatedOptions(option.translations)"
            class="min-w-[320px] mr-6 my-6"
            :value="idx"
            :modelValue="currentAnswer.multipleChoiceOption"
            :label="getTranslatedOptions(option.translations)"
            :disabled="true"
          />
        </div>
        <div v-else-if="currentQuestion.type === EQuestionType.ValueQuestion">
          <Textarea
            :modelValue="currentAnswer.inputAnswer"
            :placeholder="$t('score.record-valueQuestionTextInput')"
            :disabled="true"
          />
        </div>
        <div
          v-else-if="currentQuestion.type === EQuestionType.VirtualQuestion"
          class="flex pt-1"
        >
          <div class="flex">
            <FloatInput
              class="w-[30%] mr-3"
              v-model="currentAnswer.inputAnswer"
              :visibleHeader="false"
              :disabled="true"
            />
            <div
              class="
                min-w-[320]
                ml-3
                my-7
                text-primary
                border-primary border-[2.5px]
                rounded-3xl
                font-semibold
                bg-ultralightGray
                focus:outline-none
                py-[9px]
                px-[25px]
              "
            >
              {{ percentageDeviation }}
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div>
          {{ $t('score.record-reachedScore', { score }) }}
        </div>
        <div class="flex flex-grow justify-end">
          <ButtonWithPopup
            v-if="currentQuestion.answer"
            class="self-center"
            type="button"
            icon="chevron-down"
            iconPosition="last"
            iconClasses="w-5"
            :variant="reviewButton.variant"
            :sameWidth="false"
            :disabled="!canChangeState"
            :items="selectableStates"
            @select="updateAnswerState"
          >
            <template #text>
              {{ reviewButton.text }}
            </template>
            {{ items.text }}
          </ButtonWithPopup>
        </div>
      </template>
    </CardWithFooter>

    <FileUploadCard
      v-if="currentQuestion.answer"
      :answer="currentQuestion.answer"
      :canManageFile="false"
      :retrievalConfig="retrievalConfig"
    />

    <CommentBox
      v-if="currentQuestion.answer"
      :comments="comments"
      @postComment="postComment"
    />
    <SaveQuestionnaireDialog
      v-model="saveQuestionnaireDialog"
      title="sectionInputs.title[$i18n.locale]"
      @save="onSaveQuestionnaire"
    />
  </div>
</template>

<script>
import { sum, isEmpty, round } from 'lodash-es'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { user, question as questionHelpers } from '@/helpers'
import {
  EQuestionnaireLevel,
  EQuestionType,
  EVirtQuestType,
  EAnswerState,
  EAssetState,
  EUserRole,
} from '@/enums'
import { CommentBox } from '@/components/questionnaire'
import {
  CardWithFooter,
  FileUploadCard,
} from '@/components/questionnaire/cards'
import {
  InfoText,
  FloatInput,
  ButtonWithPopup,
  Textarea,
  BorderedRadio,
  BorderedCheckbox,
} from '@/components/form'
import { SaveQuestionnaireDialog } from '@/components/dialog'
import { config } from '@/services'

const emptyAnswer = {
  multipleChoiceOption: [],
  inputAnswer: '',
  comments: [],
}

export default {
  components: {
    CardWithFooter,
    FileUploadCard,
    ButtonWithPopup,
    InfoText,
    SaveQuestionnaireDialog,
    Textarea,
    BorderedRadio,
    BorderedCheckbox,
    CommentBox,
    FloatInput,
  },
  props: {
    clusterNum: {
      type: String,
      required: true,
    },
    categoryId: {
      type: String,
      required: true,
    },
    sectionId: {
      type: String,
      required: true,
    },
    questionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected: 'open',
      saveQuestionnaireDialog: false,
      EQuestionnaireLevel,
      EQuestionType,
      configuredNumberOfReviewedAnswers: -1,
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'assetState',
      'getQuestion',
      'getAllAnswers',
      'canBeReviewed',
    ]),
    clusterIdx() {
      return parseInt(this.clusterNum)
    },
    categoryIdInt() {
      return parseInt(this.categoryId)
    },
    sectionIdInt() {
      return parseInt(this.sectionId)
    },
    questionIdInt() {
      return parseInt(this.questionId)
    },
    retrievalConfig() {
      return {
        clusterIdx: this.clusterIdx,
        categoryId: this.categoryIdInt,
        sectionId: this.sectionIdInt,
        questionId: this.questionIdInt,
      }
    },
    currentQuestion() {
      return this.getQuestion(this.retrievalConfig)
    },
    currentAnswer() {
      return this.currentQuestion?.answer || Object.assign({}, emptyAnswer)
    },
    comments() {
      return this.currentAnswer.comments
    },
    isReviewer() {
      return user.hasPermission([
        EUserRole.InternalTester,
        EUserRole.ExternalAccreditedTester,
      ])
    },
    isEvaluator() {
      return user.hasPermission([EUserRole.Evaluator])
    },
    canChangeState() {
      if (this.isReviewer && this.assetState === EAssetState.Review) {
        return (
          this.currentAnswer.state !== EAnswerState.EvaluationAccepted &&
          (this.currentAnswer.state !== EAnswerState.Answered ||
            this.allReviewedAnswersStates.length <
              this.configuredNumberOfReviewedAnswers) &&
          this.canBeReviewed
        )
      }
      if (this.isEvaluator && this.assetState === EAssetState.Evaluation) {
        return true
      }
      return false
    },
    selectableStates() {
      let states = [
        {
          key: this.isReviewer
            ? EAnswerState.Answered
            : EAnswerState.ReviewAccepted,
          text: this.$t('score.review-inReview'),
        },
      ]

      if (this.isEvaluator || this.currentAnswer.documents.length > 0) {
        states = [
          ...states,
          {
            key: this.isReviewer
              ? EAnswerState.ReviewAccepted
              : EAnswerState.EvaluationAccepted,
            text: this.$t('score.review-accepted'),
          },
          {
            key: EAnswerState.Declined,
            text: this.$t('score.review-declined'),
          },
        ]
      }
      if (
        this.isReviewer &&
        this.currentQuestion.answer?.documents.length === 0
      ) {
        states = [
          ...states,
          {
            key: EAnswerState.Verification,
            text: this.$t('score.review-verification'),
          },
        ]
      }

      return states
    },
    reviewButton() {
      if (this.isReviewer) {
        switch (this.currentAnswer.state) {
          case EAnswerState.ReviewAccepted:
          case EAnswerState.EvaluationAccepted:
            return {
              variant: 'primary',
              text: this.$t('score.review-accepted'),
            }
          case EAnswerState.Declined:
            return { variant: 'error', text: this.$t('score.review-declined') }
          case EAnswerState.Verification:
            return {
              variant: 'yellow',
              text: this.$t('score.review-verification'),
            }

          case EAnswerState.Answered:
          default:
            return { variant: 'gray', text: this.$t('score.review-inReview') }
        }
      }

      switch (this.currentAnswer.state) {
        case EAnswerState.EvaluationAccepted:
          return { variant: 'primary', text: this.$t('score.review-accepted') }
        case EAnswerState.Declined:
          return { variant: 'error', text: this.$t('score.review-declined') }

        case EAnswerState.ReviewAccepted:
        default:
          return { variant: 'gray', text: this.$t('score.review-inReview') }
      }
    },
    percentageDeviation() {
      const tPrefix = 'score.question-virtualQuestionType'
      const deviation = round(this.currentAnswer.resultDeviation, 1)

      if (this.currentAnswer.resultDeviation === null) {
        return '—'
      }

      switch (this.currentQuestion.virtualQuestionType) {
        case EVirtQuestType.DW:
          return this.$t(`${tPrefix}-dw-${deviation < 0 ? 'below' : 'above'}`, {
            value: deviation,
            unit: '%',
          })
        case EVirtQuestType.DK:
          return this.$t(`${tPrefix}-dk-deviation`, {
            value: deviation,
            unit: '%',
          })
        case EVirtQuestType.Year:
          return this.$t(`${tPrefix}-year-departure`, {
            numberOfYears: deviation,
            year: this.currentAnswer.yearOfLeaving,
          })
        default:
          return '—'
      }
    },
    hasClarification() {
      return !isEmpty(this.defaultingLangEntry.clarification)
    },
    score() {
      const { type, scores } = this.currentQuestion
      const selectedOptions = this.currentAnswer.multipleChoiceOption

      if (selectedOptions.length === 0) {
        return 0
      }

      if (type === EQuestionType.SingleChoice) {
        return scores[selectedOptions[0]]
      } else if (type === EQuestionType.MultipleChoice) {
        return sum(scores.filter((_, idx) => selectedOptions.includes(idx)))
      }

      return 0
    },
    defaultingLangEntry() {
      return questionHelpers.defaultingLangEntry(
        this.currentQuestion.translations
      )
    },
    allReviewedAnswersStates() {
      return this.getAllAnswers()
        .map(a => a.state)
        .filter(state => state != EAnswerState.Answered)
    },
  },
  methods: {
    ...mapMutations('questionnaire', ['setAnswerState']),
    ...mapActions('questionnaire', ['addComment']),
    ...mapActions('toastMessage', ['showMessage']),
    updateAnswerState(input) {
      this.setAnswerState({
        retrievalConfig: this.retrievalConfig,
        newState: input.key,
      })
    },
    async postComment(text) {
      try {
        await this.addComment({
          retrievalConfig: this.retrievalConfig,
          comment: {
            answerId: this.currentAnswer.id,
            comment: text,
          },
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'comments-post-error',
        })
      }
    },
    openSaveQuestionnaireDialog() {
      this.saveQuestionnaireDialog = true
    },

    onSaveQuestionnaire() {
      console.log('saveQuestionnaire')
      //this.$router.replace({ name: 'editClusterCategory' })
    },
    getTranslatedOptions(langs) {
      return questionHelpers.defaultingLangEntry(langs).text
    },
  },
  async mounted() {
    this.configuredNumberOfReviewedAnswers =
      await config.getNumberOfReviewedAnswersInAssetQuestionnaire()
  },
}
</script>
