<template>
  <div>
    <DataTable
      v-bind="{
        list: groups,
        pending,
        error,
        totalItems,
        pageSize,
        header,
      }"
    >
      <template #header:actions="{ item: label }">
        <th class="text-center pr-11">
          {{ label }}
        </th>
      </template>

      <template #col:name="{ item: object }">
        <td class="font-bold pl-11">
          {{ object.name }}
        </td>
      </template>

      <template #col:organisation="{ item: object }">
        <td>{{ object.organisationName }}</td>
      </template>

      <template #col:testerName="{ item: object }">
        <td>{{ object.tester?.firstName }} {{ object.tester?.lastName }}</td>
      </template>

      <template #col:actions="{ item: object }">
        <td class="text-center pr-11">
          <Button class="w-[120px]" @click="actionButton.action(object)">
            {{ $t(actionButton.text) }}
          </Button>
        </td>
      </template>
    </DataTable>

    <AssignTesterDialog
      v-model="assignDialog"
      :assetId="selectedAssetId"
      :currentTester="selectedTester"
      :isForGroup="true"
      @finish="afterAssign"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { DataTable } from '@/components/dataTable'
import { Button } from '@/components/form'
import { general, user as userHelpers } from '@/helpers'
import { useRequest } from '@/composition'
import { EUserRole, EAssetState } from '@/enums'
import { AssignTesterDialog } from '@/components/dialog'
import { objectGroups as objectGroupsApi } from '@/services/api'
import { isNil } from 'lodash-es'

export default {
  components: {
    DataTable,
    Button,
    AssignTesterDialog,
  },
  setup() {
    const request = useRequest(objectGroupsApi.getAssetGroupsWithInfo)
    return request
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    pageSlice: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  emits: ['update:table'],
  data() {
    return {
      activeFilter: 'all',
      assignDialog: false,
      selectedAssetId: null,
      selectedTester: null,
      list: [],
    }
  },
  computed: {
    canAssignTester() {
      return userHelpers.hasPermission([EUserRole.AccountAdministrator])
    },
    actionButton() {
      if (!this.canAssignTester) return
      return {
        text: 'general.assign',
        action: this.openAssignDialog,
      }
    },
    searchTerm() {
      return this.search.trim().toLowerCase()
    },
    searchedFilteredTasks() {
      const searchKeys = ['name', 'testerName', 'organisationName']
      return general.searchOverKeys(this.list, searchKeys, this.searchTerm)
    },
    groups() {
      return this.searchedFilteredTasks.slice(...this.pageSlice)
    },
    header() {
      return ['name', 'organisation', 'testerName', 'actions'].map(key => ({
        key,
        item: this.$t(`assetManagement.objects-${key}`),
      }))
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async fetchList() {
      try {
        this.list = (await this.request()).data
      } catch (error) {
        this.showMessage({
          type: 'error',
          translationKey: 'objects-list-fetch-error',
        })
      }
    },
    formatDate(dateString) {
      return general.formatDate(dateString)
    },
    openAssignDialog({ id, tester}) {
      this.selectedAssetId = id
      if (!isNil(tester?.id) && !isNil(tester?.firstName)) {
        this.selectedTester = {
          key: tester.id,
          text: `${tester.firstName} ${tester.lastName} - ${tester.organisationName}`,
        }
      }

      this.assignDialog = true
    },
    afterAssign() {
      this.selectedAssetId = null
      this.selectedTester = null
      this.fetchList()
    },
  },
  created() {
    this.fetchList()
  },
  watch: {
    searchedFilteredTasks(newList) {
      this.$emit('update:table', newList.length)
    },
  },
}
</script>
