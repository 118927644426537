<template>
  <div>CLUSTER {{ cluster }}</div>
</template>

<script>
export default {
  props: {
    cluster: {
      type: String,
      required: true,
    },
  },
}
</script>
