<template>
  <component :is="getProperObjectGroupsView"></component>
</template>

<script>
import { EUserRole } from '@/enums'
import { user } from '@/helpers'
import {
  ObjectGroupsAccountAdministrator,
  ObjectGroupsTesters,
  ObjectGroupsEvaluator,
} from '@/views/jobManagement/objectGroupsViews'

export default {
  components: {
    ObjectGroupsAccountAdministrator,
    ObjectGroupsTesters,
    ObjectGroupsEvaluator,
  },
  computed: {
    getProperObjectGroupsView() {
      if (user.hasPermission([EUserRole.AccountAdministrator])) {
        return 'ObjectGroupsAccountAdministrator'
      } else if (user.hasPermission([EUserRole.Evaluator])) {
        return 'ObjectGroupsEvaluator'
      }
      return 'ObjectGroupsTesters'
    },
  },
}
</script>
