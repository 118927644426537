<template>
  <DynamicView :noPad="true" :breadcrumbs="breadcrumbs">
    <h1 class="pt-[1.25rem] px-[3rem] text-[1.25rem] font-medium text-white">
      {{ title || '&nbsp; ' }}
    </h1>
    <div class="flex mt-6 w-[98vw]">
      <div class="flex flex-col">
        <div class="nav-overflow pb-6 w-[363px] bg-[#DFE8E1]">
          <NavTreeQuestCheck class="pb-6" />
        </div>
      </div>
      <div class="flex-grow flex flex-col bg-lightestGray p-6">
        <div class="flex px-4" :class="saveAllRequest.pending && 'cursor-wait'">
          <ExportButton
            class="ml-auto"
            :disabled="canBeSaved || saveAllRequest.pending"
            @onExportSelected="exportAssetQuestionnaire"
          />
          <Button
            class="px-4 ml-4"
            :class="
              canBeSaved && !saveAllRequest.pending && 'hover:text-primary'
            "
            :disabled="!canBeSaved || saveAllRequest.pending"
            icon="save"
            variant="noBorder"
            @click="saveAll"
          >
            {{ $t('general.saveAll') }}
          </Button>

          <Button
            class="px-4 ml-4"
            :class="
              canSubmitForNextStep &&
              !saveAllRequest.pending &&
              'hover:text-primary'
            "
            :disabled="!canSubmitForNextStep || saveAllRequest.pending"
            :icon="nextStepButton.icon"
            variant="noBorder"
            @click="openNextStepDialog()"
          >
            {{ nextStepButton.text }}
          </Button>

          <Button
            class="px-4 ml-4"
            :class="canDecline && !saveAllRequest.pending && 'hover:text-error'"
            :disabled="!canDecline || saveAllRequest.pending"
            icon="arrow-back"
            variant="noBorder"
            @click="openDeclineDialog()"
          >
            {{ $t('score.record-decline-action') }}
          </Button>

          <Button
            v-if="isReviewer"
            class="px-4 ml-4"
            :class="
              canSubmitForVerification &&
              !saveAllRequest.pending &&
              'hover:text-yellow'
            "
            :disabled="!canSubmitForVerification || saveAllRequest.pending"
            icon="search"
            variant="noBorder"
            @click="openVerificationDialog()"
          >
            {{ $t('score.record-toVerification-action') }}
          </Button>
        </div>

        <ClipLoader
          v-if="questFetch.pending"
          class="flex flex-center h-60"
          color="green"
          size="4rem"
        />
        <router-view v-else :questionnaireId="id.toString()" />
      </div>
    </div>

    <CheckStateChangeDialog
      v-model="nextStepDialog"
      :id="assetId"
      :newState="nextAssetState"
      :isBuyerAsset="isBuyerAsset"
    />
    <ExportAssetQuestionnaireDialog
      v-model="exportAssetQuestionnaireDialog"
      v-bind="{ assetId, exportFormat,isBuyerAsset }"
      :questionnaireId="id"
    />
  </DynamicView>
</template>

<script>
import { reactive } from 'vue'
import { mapGetters, mapActions, useStore } from 'vuex'
import { EUserRole, EAssetState, EAnswerState } from '@/enums'
import { user } from '@/helpers'
import { useRequest } from '@/composition'
import { DynamicView } from '@/components/layout'
import { NavTreeQuestCheck } from '@/components/navTree'
import { Button } from '@/components/form/'
import { ExportButton } from '@/components/questionnaire'
import {
  CheckStateChangeDialog,
  ExportAssetQuestionnaireDialog,
} from '@/components/dialog'
import { config } from '@/services'
import ClipLoader from 'vue-spinner/src/ClipLoader'

export default {
  components: {
    DynamicView,
    NavTreeQuestCheck,
    Button,
    ExportButton,
    ClipLoader,
    CheckStateChangeDialog,
    ExportAssetQuestionnaireDialog,
  },

  props: {
    breadcrumbs: {
      type: Array,
    },
    assetId: {
      type: String,
      required: true,
    },
    isBuyerAsset:{
      type:Boolean,
      required:true
    }
  },

  setup(props) {
    const store = useStore()
    const questFetch = useRequest(() =>
      store.dispatch('questionnaire/fetchForAssetLatest', {assetId:props.assetId,isBuyerAsset:props.isBuyerAsset})
    )
    const saveAllRequest = useRequest(() =>
      store.dispatch('questionnaire/saveModifiedReviewedAnswers')
    )

    return reactive({
      questFetch,
      saveAllRequest,
    })
  },
  data() {
    return {
      nextAssetState: EAssetState.Declined,
      nextStepDialog: false,
      exportAssetQuestionnaireDialog: false,
      configuredNumberOfReviewedAnswers: -1,
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'id',
      'title',
      'getAllAnswers',
      'isModified',
      'assetState',
      'reviewedQuestionsPercentage',
      'evaluatedQuestionsPercentage',
      'canBeSubmitted',
      'canBeReviewed',
    ]),
    progress() {
      return this.assetState === EAssetState.Review
        ? this.reviewedQuestionsPercentage
        : this.evaluatedQuestionsPercentage
    },
    isReviewer() {
      return user.hasPermission([
        EUserRole.InternalTester,
        EUserRole.ExternalAccreditedTester,
      ])
    },
    isEvaluator() {
      return user.hasPermission([EUserRole.Evaluator])
    },
    isOnRoleMatchingState() {
      if (this.isReviewer && this.assetState === EAssetState.Review) {
        return true
      }
      if (this.isEvaluator && this.assetState === EAssetState.Evaluation) {
        return true
      }
      return false
    },
    canBeSaved() {
      return this.isModified && this.isOnRoleMatchingState
    },
    canChangeState() {
      return (
        !this.isModified &&
        this.isOnRoleMatchingState &&
        this.allAnswersStates.some(state => state != EAnswerState.Answered)
      )
    },
    canSubmitForNextStep() {
      let condition =
        this.canChangeState &&
        !this.allAnswersStates.some(
          state =>
            state == EAnswerState.Declined || state == EAnswerState.Verification
        )
      if (this.isReviewer) {
        condition =
          condition &&
          this.canBeSubmitted &&
          this.canBeReviewed &&
          this.allAnswersStates.filter(
            state => state == EAnswerState.ReviewAccepted
          ).length == this.configuredNumberOfReviewedAnswers
      } else if (this.isEvaluator) {
        condition =
          condition &&
          this.allAnswersStates.some(
            state => state == EAnswerState.EvaluationAccepted
          )
      } else {
        condition = false
      }

      return condition
    },
    canDecline() {
      const condition =
        this.canChangeState &&
        this.allAnswersStates.some(state => state == EAnswerState.Declined) &&
        this.allAnswersStates.filter(
          state =>
            state == EAnswerState.ReviewAccepted ||
            state == EAnswerState.Declined ||
            state == EAnswerState.Verification
        ).length == this.configuredNumberOfReviewedAnswers

      if (!this.isReviewer) {
        return condition
      }
      // just for reviewer
      return condition && this.canBeReviewed
    },
    canSubmitForVerification() {
      return (
        this.isReviewer &&
        this.canChangeState &&
        this.canBeReviewed &&
        this.allAnswersStates.some(
          state => state == EAnswerState.Verification
        ) &&
        this.allAnswersStates.filter(
          state =>
            state == EAnswerState.ReviewAccepted ||
            state == EAnswerState.Declined ||
            state == EAnswerState.Verification
        ).length == this.configuredNumberOfReviewedAnswers
      )
    },
    nextStepButton() {
      if (this.isReviewer) {
        return {
          text: this.$t('score.record-toEvaluation-action'),
          icon: 'assess',
        }
      }
      return {
        text: this.$t('score.record-toClosed-action'),
        icon: 'check',
      }
    },
    allAnswersStates() {
      return this.getAllAnswers().map(a => a.state)
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async saveAll() {
      try {
        await this.saveAllRequest.request()
        await this.questFetch.request()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-question-reviewSaveAll-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-question-reviewSaveAll-error',
        })
      }
    },
    openNextStepDialog() {
      if (this.assetState === EAssetState.Review) {
        this.nextAssetState = EAssetState.Evaluation
      } else {
        this.nextAssetState = EAssetState.Closed
      }
      this.nextStepDialog = true
    },
    openDeclineDialog() {
      this.nextAssetState = EAssetState.Declined
      this.nextStepDialog = true
    },
    openVerificationDialog() {
      this.nextAssetState = EAssetState.Verification
      this.nextStepDialog = true
    },
    exportAssetQuestionnaire(exportFormat) {
      this.exportFormat = exportFormat
      this.exportAssetQuestionnaireDialog = true
    },
  },
  async mounted() {
    this.configuredNumberOfReviewedAnswers =
      await config.getNumberOfReviewedAnswersInAssetQuestionnaire()
  },

  async created() {
    try {
      await this.questFetch.request()
    } catch {
      this.showMessage({
        type: 'error',
        translationKey: 'questionnaire-fetch-error',
      })
      this.$router.push({ name: 'jobOverview' })
    }
  },
}
</script>

<style scoped>
.nav-overflow {
  flex-basis: 450px;
  flex-grow: 1;
  overflow-y: scroll;
}
</style>
