<template>
  <DynamicView :breadcrumbs="breadcrumbs">
    <TableView
      :heading="$t(`pages.jobManagement`)"
      :description="$t(`assetManagement.jobManagement-fullRights`)"
      v-bind="{ tabs }"
    />
  </DynamicView>
</template>

<script>
import { TableView, DynamicView } from '@/components/layout'

export default {
  components: {
    TableView,
    DynamicView,
  },
  props: {
    breadcrumbs: {
      type: Array,
    },
  },
  data() {
    return {}
  },
  computed: {
    tabs() {
      return [
        {
          routeName: 'jobObjectGroupManagement',
          label: this.$t('assetManagement.objectGroups-tab'),
        },
        {
          routeName: 'jobObjectManagement',
          label: this.$t('assetManagement.objects-tab'),
        },
      ]
    },
  },
  methods: {},
}
</script>
