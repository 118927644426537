<template>
  <div>
    <DataTable
      v-bind="{
        list: tasksPage,
        pending,
        error,
        totalItems,
        pageSize,
        filters,
        header,
      }"
      @selected:filter="onFilterSelect"
      :isFixedLayout="!this.isEvaluator"
    >
      <template #header:actions="{ item: label }">
        <th class="text-center pr-11">
          {{ label }}
        </th>
      </template>

      <template #col:name="{ item: object }">
        <td class="font-bold pl-11">
          {{ object.assetName }}
        </td>
      </template>

      <template #col:objectGroup="{ item: object }">
        <td>{{ object.assetGroupName }}</td>
      </template>

      <template #col:organisation="{ item: object }">
        <td>{{ object.organisationName }}</td>
      </template>

      <template #col:testerName="{ item: object }">
        <td>{{ object.testerName }}</td>
      </template>

      <template #col:status="{ item: object }">
        <td>{{ object.stateName }}</td>
      </template>

      <template #col:actions="{ item: object }">
        <td class="text-center pr-11 whitespace-nowrap">
          <Button class="w-[120px]" @click="actionButton.action(object)">
            {{ $t(actionButton.text) }}
          </Button>
          <Button
            v-if="this.isEvaluator"
            class="ml-7 px-5"
            variant="primary"
            :disabled="disableDownloadLabelButton(object.state)"
            @click="
              this.$router.push({
                name: 'certificate',
                params: { assetId: object.assetId, isGroup: false },
              })
            "
          >
            {{ $t('general.download-label') }}
          </Button>
        </td>
      </template>
    </DataTable>

    <AssignTesterDialog
      v-model="assignDialog"
      :assetId="selectedAssetId"
      :currentTester="selectedTester"
      @finish="afterAssign"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { DataTable } from '@/components/dataTable'
import { Button } from '@/components/form'
import { general, user as userHelpers } from '@/helpers'
import { useRequest } from '@/composition'
import { ECheckedStatus, EUserRole, EAssetState } from '@/enums'
import { AssignTesterDialog } from '@/components/dialog'
import { objects as objectsApi } from '@/services/api'
import { isNil } from 'lodash-es'

export default {
  components: {
    DataTable,
    Button,
    AssignTesterDialog,
  },
  setup() {
    const request = useRequest(objectsApi.getQuestionnairesInfos)
    return request
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    pageSlice: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  emits: ['update:table'],
  data() {
    return {
      ECheckedStatus,
      activeFilter: 'all',
      assignDialog: false,
      selectedAssetId: null,
      selectedTester: null,
      list: [],
    }
  },
  computed: {
    canAssignTester() {
      return userHelpers.hasPermission([EUserRole.AccountAdministrator])
    },
    isEvaluator() {
      return userHelpers.hasPermission([EUserRole.Evaluator])
    },
    actionButton() {
      return this.canAssignTester
        ? {
            text: 'general.assign',
            action: this.openAssignDialog,
          }
        : {
            text: 'general.check',
            action: this.openCheck,
          }
    },
    uncheckedTasks() {
      if (this.isEvaluator) {
        return this.list.filter(t => t.state === EAssetState.Evaluation)
      }
      return this.list.filter(t => t.state === EAssetState.Review)
    },
    checkedTasks() {
      if (this.isEvaluator) {
        return this.list.filter(
          t =>
            t.state !== EAssetState.Evaluation &&
            t.state !== EAssetState.DefinitelyDeclined
        )
      }
      return this.list.filter(t => t.state !== EAssetState.Review)
    },
    definitelyDeclinedTasks() {
      return this.list.filter(t => t.state === EAssetState.DefinitelyDeclined)
    },
    assignedTasks() {
      return this.list.filter(t => t.testerId)
    },
    unassignedTasks() {
      return this.list.filter(t => !t.testerId)
    },
    filteredTasks() {
      switch (this.activeFilter) {
        case 'checked':
          return this.checkedTasks
        case 'unchecked':
          return this.uncheckedTasks
        case 'assigned':
          return this.assignedTasks
        case 'unassigned':
          return this.unassignedTasks
        case 'definitelyDeclined':
          return this.definitelyDeclinedTasks
        default:
          return this.list
      }
    },
    searchTerm() {
      return this.search.trim().toLowerCase()
    },
    searchedFilteredTasks() {
      const searchKeys = [
        'assetName',
        'assetGroupName',
        'organisationName',
        'testerName',
      ]
      return general.searchOverKeys(
        this.filteredTasks,
        searchKeys,
        this.searchTerm
      )
    },
    tasksPage() {
      return this.searchedFilteredTasks.slice(...this.pageSlice)
    },
    filters() {
      return this.canAssignTester
        ? [
            {
              key: 'all',
              item: {
                label: this.$t('assetManagement.all'),
                active: this.activeFilter === 'all',
                number: this.list.length,
              },
            },
            {
              key: 'unassigned',
              item: {
                label: this.$t('assetManagement.objects-unassigned'),
                active: this.activeFilter === 'unassigned',
                number: this.unassignedTasks.length,
              },
            },
            {
              key: 'assigned',
              item: {
                label: this.$t('assetManagement.objects-assigned'),
                active: this.activeFilter === 'assigned',
                number: this.assignedTasks.length,
              },
            },
          ]
        : [
            {
              key: 'all',
              item: {
                label: this.$t('assetManagement.all'),
                active: this.activeFilter === 'all',
                number: this.list.length,
              },
            },
            {
              key: 'unchecked',
              item: {
                label: this.$t(
                  'assetManagement.objects-checkedStatus-unchecked'
                ),
                active: this.activeFilter === 'unchecked',
                number: this.uncheckedTasks.length,
              },
            },
            {
              key: 'checked',
              item: {
                label: this.$t('assetManagement.objects-checkedStatus-checked'),
                active: this.activeFilter === 'checked',
                number: this.checkedTasks.length,
              },
            },
            ...(this.isEvaluator
              ? [
                  {
                    key: 'definitelyDeclined',
                    item: {
                      label: this.$t(
                        'assetManagement.objects-status-definitelyDeclined'
                      ),
                      active: this.activeFilter === 'definitelyDeclined',
                      number: this.definitelyDeclinedTasks.length,
                    },
                  },
                ]
              : []),
          ]
    },
    header() {
      return [
        'name',
        'objectGroup',
        'organisation',
        'status',
        'testerName',
        'actions',
      ].map(key => ({
        key,
        item: this.$t(`assetManagement.objects-${key}`),
      }))
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async fetchList() {
      try {
        const list = await this.request()
        this.list = list.map(item => {
          return { ...item, stateName: this.assetTaskStatus(item) }
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'objects-list-fetch-error',
        })
      }
    },
    formatDate(dateString) {
      return general.formatDate(dateString)
    },
    onFilterSelect(key) {
      this.activeFilter = key
    },
    openCheck(object) {
      this.$router.push({
        name: 'checkAsset',
        params: { assetId: object.assetId, isBuyerAsset:object.isBuyerAsset },
      })
    },
    openAssignDialog({ assetId, testerId, testerName,testerOrganization }) {
      this.selectedAssetId = assetId
      if (!isNil(testerId) && !isNil(testerName)) {
        this.selectedTester = {
          key: testerId,
          text: `${testerName} - ${testerOrganization}`,
        }
      }

      this.assignDialog = true
    },
    afterAssign() {
      this.selectedAssetId = null
      this.selectedTester = null
      this.fetchList()
    },
    assetTaskStatus(item) {
      // AccountAdministrator
      if (this.canAssignTester) {
        return item.testerId
          ? this.$t('assetManagement.objects-assigned')
          : this.$t('assetManagement.objects-unassigned')
      }

      if (this.isEvaluator) {
        if (item.state === EAssetState.Evaluation)
          return this.$t('assetManagement.objects-checkedStatus-unchecked')
        if (item.state === EAssetState.DefinitelyDeclined)
          return this.$t('assetManagement.objects-status-definitelyDeclined')
        return this.$t('assetManagement.objects-checkedStatus-checked')
      }

      // Testers
      return item.state === EAssetState.Review
        ? this.$t('assetManagement.objects-checkedStatus-unchecked')
        : this.$t('assetManagement.objects-checkedStatus-checked')
    },
    disableDownloadLabelButton(state) {
      return ![EAssetState.Closed].includes(state)
    },
  },
  created() {
    this.fetchList()
  },
  watch: {
    searchedFilteredTasks(newList) {
      this.$emit('update:table', newList.length)
    },
  },
}
</script>
