<template>
  <div>
    <div class="flex text-[16px] font-semibold text-gray mt-3 mb-2">
      <router-link
        v-for="{ routeName, label } in tabs"
        :key="label"
        class="hover:text-primary min-w-[135px] py-[5px] px-[38px]"
        :class="$route.name === routeName ? 'text-primary' : 'text-gray'"
        :to="{ name: routeName }"
      >
        {{ label }}
      </router-link>
    </div>
    <QuestionnaireScoreInfo
      :assetId="assetId"
      :questionnaireId="questionnaireId"
      :isBuyerAsset="isBuyerAsset"
    />
  </div>
</template>

<script>
import { QuestionnaireScoreInfo } from '@/components/questionnaire/score'

export default {
  components: {
    QuestionnaireScoreInfo,
  },
  props: {
    questionnaireId: {
      type: String,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
    isBuyerAsset:{
      type:Boolean,
      required:true
    }
  },
  computed: {
    tabs() {
      return [
        {
          routeName: 'metadataCheckOverview',
          label: this.$t('assetManagement.resultOverview-tab'),
        },
        {
          routeName: 'metadataCheckDetails',
          label: this.$t('assetManagement.resultDetail-tab'),
        },
      ]
    },
  },
}
</script>
