<template>
  <ClipLoader
    v-if="pending"
    class="flex-grow flex flex-center"
    color="green"
    size="4rem"
  />
  <BaseData v-else-if="object" :object="object" disallowEdit />
</template>

<script>
import { mapActions } from 'vuex'
import { objects as objectsApi } from '@/services/api'
import { useRequest } from '@/composition'
import BaseData from '@/views/assetManagement/editObject/BaseData'
import ClipLoader from 'vue-spinner/src/ClipLoader'

export default {
  components: {
    BaseData,
    ClipLoader,
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
    isBuyerAsset:{
      type:Boolean,
      required:true
    }
  },
  setup(props) {
    const objectRequest = useRequest(() => objectsApi.getById(props.assetId,props.isBuyerAsset))
    return objectRequest
  },
  data() {
    return {
      object: null,
    }
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
  },
  async created() {
    try {
      this.object = await this.request()
    } catch {
      this.showMessage({
        type: 'error',
        translationKey: 'objects-fetch-error',
      })
    }
  },
}
</script>
