<template>
  <div class="bg-white rounded-b-md rounded-tr-md pt-4">
    <table class="table-auto w-full">
      <thead class="text-left text-sm text-lightBlack bg-oliveGreen">
        <tr class="border-t border-b border-[#e7e6e6]">
          <th class="py-2 pl-[5.25rem]">
            {{ $t('assetManagement.objectGroups-name') }}
          </th>
          <th class="py-2">
            {{ $t('assetManagement.objectGroups-id') }}
          </th>
          <th class="py-2">
            {{ $t('assetManagement.objectGroups-organisation') }}
          </th>
          <th class="py-2">
            {{ $t('assetManagement.objectGroups-status') }}
          </th>
          <th class="py-2">
            {{ $t('assetManagement.objectGroups-testerName') }}
          </th>
          <th class="text-center py-2 pr-11">
            {{ $t('assetManagement.objectGroups-actions') }}
          </th>
        </tr>
      </thead>

      <tbody class="text-lightBlack">
        <template v-if="groups.length > 0">
          <JobObjectGroupRow
            v-for="(group, idx) in groups"
            :key="idx"
            :group="group"
            :isEvaluator="true"
            @evaluate="openEvaluateGroupDialog"
          />
          <tr v-for="idx in numPlaceholders" :key="idx" class="item-row"></tr>
        </template>

        <tr v-else>
          <td class="text-center font-medium py-16" colspan="6">
            {{
              pending
                ? `${$t('general.loading')} ...`
                : error
                ? `${$t('table.loadError')}.`
                : `${$t('table.empty')}.`
            }}
          </td>
        </tr>
      </tbody>
    </table>

    <EvaluateGroupDialog
      v-model="submitForEvaluateDialog"
      :group="selectedGroup"
      :accept="acceptGroup"
      :id="selectedGroup?.id ?? ''"
      :name="selectedGroup?.name ?? ''"
      @finish="groupEvaluated"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { JobObjectGroupRow } from '@/components/dataTable'
import { EvaluateGroupDialog } from '@/components/dialog'
import { useRequest } from '@/composition'
import { objectGroups as objectGroupsApi } from '@/services/api'
import { general } from '@/helpers'

export default {
  components: {
    JobObjectGroupRow,
    EvaluateGroupDialog,
  },
  setup() {
    const request = useRequest(objectGroupsApi.getAssetGroupsWithInfo)
    return request
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    pageSlice: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  emits: ['update:table'],
  data() {
    return {
      submitForEvaluateDialog: false,
      selectedGroup: null,
      acceptGroup: false,
      list: [],
    }
  },
  computed: {
    searchTerm() {
      return this.search.trim().toLowerCase()
    },
    searchedFilteredTasks() {
      const searchKeys = ['name', 'organisationName']
      return general.searchOverKeys(this.list, searchKeys, this.searchTerm)
    },
    groups() {
      return this.searchedFilteredTasks.slice(...this.pageSlice)
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async fetchList() {
      try {
        const list = await this.request()
        this.list = list.data
      } catch (error) {
        this.showMessage({
          type: 'error',
          translationKey: 'objects-list-fetch-error',
        })
      }
    },
    groupEvaluated() {
      this.fetchList()
    },
    openEvaluateGroupDialog(group, accept) {
      console.log('action accept', group, accept)
      this.selectedGroup = group
      this.acceptGroup = accept
      this.submitForEvaluateDialog = true
    },
    acceptOrDeclineGroup(accept) {
      console.log('action accept', accept)
    },
  },
  created() {
    this.fetchList()
  },
  watch: {
    searchedFilteredTasks(newList) {
      this.$emit('update:table', newList.length)
    },
  },
}
</script>

<style scoped>
.item-row {
  @apply border-lighterGray border-b last:border-b-0 h-16;
}
</style>
