<template>
  <router-view :breadcrumbs="breadcrumbs" />
</template>

<script>
import { last } from 'lodash-es'
import { mapGetters } from 'vuex'
import { general, question as questionHelpers } from '@/helpers'

export default {
  computed: {
    ...mapGetters('questionnaire', ['title', 'clusters']),
    breadcrumbs() {
      const result = [
        {
          routeName: 'jobManagement',
          text: this.$t('pages.jobManagement'),
        },
      ]

      const matchedNames = this.$route.matched.slice(2).map(r => r.name)

      if (matchedNames[0] === 'jobOverview') {
        result.push({ text: this.$t('pages.jobObjectGroupManagement') })
        return result
      }

      if (matchedNames[0] !== 'checkAsset' || !this.title) {
        return result
      }

      result.push({
        routeName: 'metadataCheckOverview',
        text: this.title,
      })

      const lastMatchedName = last(matchedNames)

      if (lastMatchedName === 'checkShowObjectData') {
        result.push({ text: this.$t('pages.editObjectInfo') })
        return result
      }

      if (
        ['metadataCheckOverview', 'metadataCheckDetails'].includes(
          lastMatchedName
        )
      ) {
        result.push({ text: this.$t('pages.metadata') })
        return result
      }

      result.push({
        text: `Cluster ${general.numeral(
          parseInt(this.$route.params.clusterNum) + 1
        )}`,
      })

      const cluster = this.clusters[this.$route.params.clusterNum]
      let category

      if (
        [
          'checkClusterCategory',
          'checkClusterSection',
          'checkQuestion',
        ].includes(lastMatchedName)
      ) {
        category = cluster.categories.find(
          c => `${c.id}` === this.$route.params.categoryId
        )
        if (!category) {
          return result
        }
        result.push({
          text: questionHelpers.defaultingLangEntry(category.name).name,
        })
      }

      let section

      if (['checkClusterSection', 'checkQuestion'].includes(lastMatchedName)) {
        section = category.sections.find(
          s => `${s.id}` === this.$route.params.sectionId
        )
        if (!section) {
          return result
        }
        result.push({
          text: questionHelpers.defaultingLangEntry(section.name).name,
        })
      }

      if (['checkQuestion'].includes(lastMatchedName)) {
        const question = section.questions.find(
          s => `${s.id}` === this.$route.params.questionId
        )
        if (!question) {
          return result
        }
        const translation = questionHelpers.defaultingLangEntry(
          question.translations
        )
        result.push({ text: translation.title })
      }

      return result
    },
  },
}
</script>
